import React, { useState } from 'react';
import Button from '../../buttons/Button';

const AddEditor = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to add editor
    console.log(`Editor added: ${username}, ${email}`);
  };

  return (
    <div className="d-flex justify-content-center">
    <div className="card col-md-6">
      <div className="card-body">
        <h5 className="card-title">Add Editor</h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button type="submit">Add Editor</Button>
        </form>
      </div>
    </div>
    </div>

  );
};

export default AddEditor;
