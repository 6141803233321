import React, { useState, useEffect } from 'react';
import Spinner from './Spinner'; // Assurez-vous que Spinner est bien importé

const AppLoader = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simule le chargement des ressources
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Vous pouvez ajuster la durée selon les besoins

    return () => clearTimeout(timer);
  }, []);

  return isLoading ? <Spinner /> : children;
};

export default AppLoader;
