import React from 'react';

const ScrollToTop = () => {
  return (
    <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
    </a>
  );
};

export default ScrollToTop;
