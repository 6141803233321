import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TeamMember = ({ image, name, designation, socialLinks = [] }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
      <div className="team-item">
        {/* Image Section */}
        <div className="overflow-hidden position-relative">
          <img className="img-fluid" src={image} alt={` ${name}`} />
          <div className="team-social position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
            {socialLinks.length > 0 ? (
              socialLinks.map((link, index) =>
                link.url && link.icon ? (
                  <a
                    key={index}
                    className="btn btn-square btn-dark rounded-circle m-1"
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Follow ${name} on ${link.icon.split('-').pop()}`}
                  >
                    <i className={link.icon}></i>
                  </a>
                ) : null
              )
            ) : (
              <span className="text-light">No Social Links</span>
            )}
          </div>
        </div>

        {/* Text Section */}
        <div className="text-center p-4">
          <h5 className="mb-0">{name}</h5>
          <span className="text-primary">{designation}</span>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
