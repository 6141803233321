import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'jquery.easing';
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "animate.css/animate.min.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'lightbox2/dist/css/lightbox.min.css';
import lightbox from 'lightbox2';
import "./assets/css/style.css";
import "./assets/js/main";
import 'animate.css';

import AppWrapper from "./AppWrapper";
import ErrorBoundary from "./components/ErrorBoundary"; 
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Spinner from "./components/Spinner";
import AppLoader from './components/AppLoader'; // Import the AppLoader component
import Login from "./pages/login";

const App = () => {
  useEffect(() => {
    lightbox.option({
      resizeDuration: 200,
      wrapAround: true
    });
  }, []);

  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <AppLoader>
            <Routes>
              <Route path="/dashboard/" element={<Dashboard />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/AppWrapper/" element={<AppWrapper />} />
              <Route path="/" element={<AppWrapper />} />
            </Routes>
          </AppLoader>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
