import $ from 'jquery';
import 'jquery.easing';
import 'owl.carousel';
import WOW from 'wow.js';
import counterUp from 'counterup2';
import Isotope from 'isotope-layout';
import 'lightbox2';

$(document).ready(function () {
  // Initialize WOW.js
  new WOW().init();

  // Spinner
  const spinner = function () {
    setTimeout(function () {
      if ($('#spinner').length > 0) {
        $('#spinner').removeClass('show');
      }
    }, 1000); // corrected delay to 1000ms (1 second)
  };
  spinner();

  // Sticky Navbar
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $('.sticky-top').addClass('shadow-sm').css('top', '0px');
    } else {
      $('.sticky-top').removeClass('shadow-sm').css('top', '-100px');
    }
  });

  // Back to top button
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });
  $('.back-to-top').click(function () {
    $('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
    return false;
  });

  // Facts counter
  $('[data-toggle="counter-up"]').each(function () {
    counterUp(this, {
      delay: 10,
      time: 2000,
    });
  });

  // Portfolio isotope and filter
 
});
