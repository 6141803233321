import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png"; // Assurez-vous que le chemin est correct

const Navbar = () => {
  // Supposons que vous ayez une fonction qui vérifie si l'utilisateur est connecté
  const isAuthenticated = false; // Remplacez cela par votre logique d'authentification

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5">
      <Link to="/" className="navbar-brand d-flex align-items-center">
        <img src={logo} alt="Cswt Logo" style={{ height: "80px" }} />
        <h3 className="m-0 text-primary ms-2">Cswt</h3>
      </Link>
      <button
        type="button"
        className="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-4 py-lg-0">
          <Link to="/" className="nav-item nav-link active">
            Accueil
          </Link>
          <Link to="/contact" className="nav-item nav-link">
            Contact
          </Link>
          {isAuthenticated && (
            <Link to="/dashboard" className="nav-item nav-link">
              Dashboard
            </Link>
          )}
          {!isAuthenticated && (
            <Link to="/login" className="nav-item nav-link">
              Connexion
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
