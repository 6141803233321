import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import portfolio1 from '../../assets/img/fuitednné.jpg';
import portfolio2 from '../../assets/img/sufacedattque.jpg';
import portfolio3 from '../../assets/img/analyserisque.jpg';

const Projects = () => {
  const [modalImage, setModalImage] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const projects = [
    {
      id: 1,
      category: 'Surveillance des Fuites de Données',
      title: 'Protection proactive contre les fuites de données',
      image: portfolio1,
      filter: 'first',
    },
    {
      id: 2,
      category: 'Cartographie de la Surface d\'Attaque',
      title: 'Visualisation et gestion de la surface d\'attaque',
      image: portfolio2,
      filter: 'second',
    },
    {
      id: 3,
      category: 'Visualisation des Risques',
      title: 'Évaluation graphique des risques potentiels',
      image: portfolio3,
      filter: 'first',
    },
  ];

  const openModal = (image) => {
    setModalImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalImage('');
    setModalOpen(false);
  };

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center" data-aos="fade-up" data-aos-delay="100">
          <div className="bg-primary mb-3 mx-auto" style={{ width: '60px', height: '2px' }}></div>
          <h1 className="display-5 mb-5">Nos Projets</h1>
        </div>
        <div className="row mt-n2" data-aos="fade-up" data-aos-delay="300"></div>
        <div className="row g-4 portfolio-container">
          {projects.map((project) => (
            <div
              key={project.id}
              className={`col-lg-4 col-md-6 portfolio-item ${project.filter}`}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="portfolio-inner" style={{ width: '300px', height: '400px', overflow: 'hidden' }}>
                <img
                  className="img-fluid w-100"
                  src={project.image}
                  alt={project.title}
                  style={{ height: '200px', objectFit: 'cover' }}
                  onClick={() => openModal(project.image)}
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">{project.category}</p>
                  <h5 className="lh-base mb-0">{project.title}</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">{project.category}</p>
                  <h5 className="lh-base mb-3">{project.title}</h5>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-square btn-primary rounded-circle mx-1"
                     
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                   
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <img src={modalImage} className="img-fluid" alt="modal content" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
