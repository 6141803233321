import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png"; // Assurez-vous que le chemin est correct

const Sidebar = ({ setActiveContent }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    components: false,
    utilities: false,
    pages: false,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const savedActiveContent = localStorage.getItem("activeContent");
    if (savedActiveContent) {
      setActiveContent(savedActiveContent);
    }

    const style = document.createElement("style");
    style.innerHTML = `
      .toggled {
        width: 0 !important;
        overflow: hidden !important;
      }
      .toggled .nav-item,
      .toggled .sidebar-heading,
      .toggled .sidebar-divider,
      .toggled .sidebar-card {
        display: none !important;
      }
      .toggled .sidebar-brand {
        display: flex !important;
      }
      @media (max-width: 768px) {
        .sidebar {
          position: fixed;
          width: 250px;
          left: -250px;
          top: 0;
          height: 100%;
          z-index: 1050;
          transition: left 0.3s ease-in-out;
        }
        .toggled {
          left: 0 !important;
        }
      }
      @media (min-width: 769px) {
        .sidebar {
          display: block;
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [setActiveContent]);

  const toggleDropdown = (dropdown) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const handleSetActiveContent = (content) => {
    setActiveContent(content);
    localStorage.setItem("activeContent", content);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    document.getElementById("accordionSidebar").classList.toggle("toggled");
  };

  const toggleButtonStyle = {
    position: "fixed",
    top: "10px",
    left: "10px",
    zIndex: 1000,
    backgroundColor: "#4e73df",
    color: "white",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
  };

  const toggleButtonHoverStyle = {
    backgroundColor: "#2e59d9",
  };

  return (
    <>
      <ul
        className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion`}
        id="accordionSidebar"
      >
        <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
          <div className="sidebar-brand-icon rotate-n-15">
            <img src={logo} alt="Cswt Logo" style={{ height: "80px" }} />
          </div>
          <div className="sidebar-brand-text mx-3">
            Super Admin <sup>Cwst</sup>
          </div>
        </Link>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item active">
          <Link className="nav-link" to="/">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Home</span>
          </Link>
        </li>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item active">
          <a className="nav-link" href="#" onClick={() => handleSetActiveContent("register")}>
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Interface</div>
        <li className="nav-item">
          <a className="nav-link collapsed" href="#" onClick={() => toggleDropdown("components")}>
            <i className="fas fa-fw fa-cog"></i>
            <span>Gestion des Compagnies</span>
          </a>
          <div className={`collapse ${isDropdownOpen.components ? "show" : ""}`} id="collapseComponents">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Sélectionner:</h6>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("Addcompainie")}>
                Ajouter une compagnie
              </a>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("Companielist")}>
                Gérer les compagnies
              </a>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <a className="nav-link collapsed" href="#" onClick={() => toggleDropdown("utilities")}>
            <i className="fas fa-fw fa-wrench"></i>
            <span>Admins</span>
          </a>
          <div className={`collapse ${isDropdownOpen.utilities ? "show" : ""}`} id="collapseUtilities">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Gérer tout les admins Cwst:</h6>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("addAdmin")}>
                Ajouter un admin Cwst
              </a>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("manageAdmins")}>
                Gérer les admins Cwst
              </a>
              <h6 className="collapse-header">Gérer tout les éditeurs</h6>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("addEditor")}>
                Ajouter un Editeur
              </a>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("manageEditors")}>
                Gérer les Editeurs
              </a>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={() => handleSetActiveContent("alerts")}>
            <i className="fas fa-fw fa-bell"></i>
            <span>Alertes</span>
          </a>
        </li>
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Addons</div>
        <li className="nav-item">
          <a className="nav-link collapsed" href="#" onClick={() => toggleDropdown("pages")}>
            <i className="fas fa-fw fa-folder"></i>
            <span>Gestion Abonnement</span>
          </a>
          <div className={`collapse ${isDropdownOpen.pages ? "show" : ""}`} id="collapsePages">
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header"></h6>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("addPack")}>
                Ajouter un pack
              </a>
              <a className="collapse-item" href="#" onClick={() => handleSetActiveContent("managePacks")}>
                Gérer les packs d'Abonnement
              </a>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={() => handleSetActiveContent("charts")}>
            <i className="fas fa-fw fa-chart-area"></i>
            <span>Statistiques</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#" onClick={() => handleSetActiveContent("tables")}>
            <i className="fas fa-fw fa-table"></i>
            <span>Tables</span>
          </a>
        </li>
        <hr className="sidebar-divider d-none d-md-block" />
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle" onClick={toggleSidebar}></button>
        </div>
        <div className="sidebar-card d-none d-lg-flex">
          <img
            className="sidebar-card-illustration mb-2"
            src="img/undraw_rocket.svg"
            alt="..."
          />
          <p className="text-center mb-2">
            <strong>Super Admin Pro</strong> vous offre un support technique premium !
          </p>
        </div>
      </ul>
    </>
  );
};

export default Sidebar;
