import React from 'react';
import Toopbar from '../DashTopbar';
import AddUserForm from '../Register'; 
import PageHeading from '../PageHeading'; 
import ContentRow from '../ContentRow'; 
import ProjectCard from '../ProjectCard'; 
import AddAdmin from '../composant/forms/add/AddAdmin'; 
import AddEditor from '../composant/forms/add/AddEditor'; 
import AddCompainie from '../composant/forms/add/AddCompainie'; 
import AdminList from '../composant/contents/liste/adminList'; 
import CompanieList from '../composant/contents/liste/compagnieList'; 

const ContentWrapper = ({ activeContent }) => {
  const renderContent = () => {
    switch (activeContent) {
      case 'register':
        return <AddUserForm />;
      case 'addAdmin':
        return <AddAdmin text="Ajouter un Admin" />;
      case 'Companielist':
        return <CompanieList text="Gérer les compagnies" />;
      case 'Addcompainie':
        return <AddCompainie text="Ajouter une compagnie" />;
      case 'manageAdmins':
        return <AdminList text="Gérer les Admins" />;
      case 'addEditor':
        return <AddEditor text="Ajouter un Éditeur" />;
      case 'manageEditors':
        return <PageHeading text="Gérer les Éditeurs" />;
      case 'alerts':
        return <PageHeading text="Alertes" />;
      case 'addPack':
        return <PageHeading text="Ajouter un Pack" />;
      case 'managePacks':
        return <PageHeading text="Gérer les Packs d'Abonnement" />;
      case 'charts':
        return <ProjectCard text="Statistiques" />;
      case 'tables':
        return <ContentRow text="Tables" />;
      default:
        return <PageHeading text="Le contenu demandé n'existe pas." />;
    }
  };

  return (
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <Toopbar />
        <div className="container-fluid">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default ContentWrapper;
