import React from 'react';
import Card from './Card';

const ContentRow = () => {
  return (
    <div className="row">
      <Card
        borderClass="border-left-primary"
        iconClass="fas fa-calendar fa-2x text-gray-300"
        title="Earnings (Monthly)"
        value="$40,000"
      />
      <Card
        borderClass="border-left-success"
        iconClass="fas fa-dollar-sign fa-2x text-gray-300"
        title="Earnings (Annual)"
        value="$215,000"
      />
      <Card
        borderClass="border-left-info"
        iconClass="fas fa-clipboard-list fa-2x text-gray-300"
        title="Tasks"
        value="50%"
        extraContent={
          <div className="row no-gutters align-items-center">
            <div className="col-auto">
              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
            </div>
            <div className="col">
              <div className="progress progress-sm mr-2">
                <div className="progress-bar bg-info" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        }
      />
      <Card
        borderClass="border-left-warning"
        iconClass="fas fa-comments fa-2x text-gray-300"
        title="Pending Requests"
        value="18"
      />
    </div>
  );
};

export default ContentRow;
