import React from 'react';
import Navbar from '../components/Navbar';  
import Footer from '../components/Footer';  
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const NotFound = () => {
  return (
    <>
      <Navbar />
     
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
        <DotLottieReact
          src="https://lottie.host/c97b6635-d46f-4ed9-a7e8-78495e65d596/gfhzkxqxtz.lottie"
          loop
          autoplay
          style={{ width: '60%', height: 'auto' }}  // Taille ajustée
        />
      </div>

<div className="container text-center">
    
<p className="lead">Désolé, la page que vous cherchez n'existe pas.</p>
        <a href="/" className="btn btn-primary">Retour à l'accueil</a>
</div>
      <Footer />
    </>
  );
};

export default NotFound;
