import React from "react";
import Carousel from "../components/home/Carousel";
import Facts from "../components/home/Facts";
import About from "../components/home/About";
import Service from "../components/home/Service";
import Projects from "../components/home/Projects";
import Quote from "../components/home/Quote";
import Team from "../components/home/Team";
import Testimonial from "../components/home/Testimonial";


const Home = () => {

  
  return (
    <div>
      <Carousel />
      <Facts />
      <About />
      <Service />
      <Projects />
      <Quote />
      
      {/*  les membres de l'équipe au composant Team */}
      <Team />
      <Testimonial />

    </div>
  );
};

export default Home;
