import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Login from "./pages/login";
import Dashboard from "./pages/Dashboard"; // Assurez-vous d'avoir ce composant
import Profile from "./pages/Profile"; // Import Profile componentsss

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SocialButton from "./components/SocialButton";

import Copyright from "./components/Copyright";

const AppWrapper = () => {
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";

  return (
    <div className="App">
      {!isDashboard && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
     
        {/* Ajoutez d'autres routes si nécessaire */}
      </Routes>
      {!isDashboard && <Footer />}
      {!isDashboard && <Copyright />}
      {!isDashboard && <SocialButton />}
    </div>
  );
};

export default AppWrapper;
