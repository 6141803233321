import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TeamMember from "./TeamMember";
import team1 from "../../assets/img/team-1.jpg"; // Assurez-vous que les chemins sont corrects
import team2 from "../../assets/img/team-2.jpg";
import team3 from "../../assets/img/team-3.jpg";
import team4 from "../../assets/img/team-4.jpg";

const teamMembers = [

  
  {
    image: team1,
    name: "John Doe",
    designation: "CEO",
    socialLinks: [
      { url: "#", icon: "fab fa-facebook-f" },
      { url: "#", icon: "fab fa-twitter" },
      { url: "#", icon: "fab fa-instagram" },
    ],
  },
  {
    image: team2,
    name: "Jane Smith",
    designation: "CTO",
    socialLinks: [
      { url: "#", icon: "fab fa-facebook-f" },
      { url: "#", icon: "fab fa-twitter" },
      { url: "#", icon: "fab fa-instagram" },
    ],
  },
  {
    image: team3,
    name: "Sam Wilson",
    designation: "Lead Developer",
    socialLinks: [
      { url: "#", icon: "fab fa-facebook-f" },
      { url: "#", icon: "fab fa-twitter" },
      { url: "#", icon: "fab fa-instagram" },
    ],
  },
  {
    image: team4,
    name: "Sara Connor",
    designation: "Project Manager",
    socialLinks: [
      { url: "ZERTYUI.com", icon: "fab fa-facebook-f" },
      { url: "#", icon: "fab fa-twitter" },
      { url: "#", icon: "fab fa-instagram" },
    ],
  },
];

const Team = ({ members = teamMembers }) => {
  useEffect(() => { AOS.init({ duration: 1000 }); }, []);
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s" data-aos="fade-up" data-aos-delay="100">
          <div
            className="bg-primary mb-3 mx-auto"
            style={{ width: "60px", height: "2px" }} 
          ></div>
          <h1 className="display-5 mb-5"> Membres de L'équipes</h1>
        </div>
        <div className="row g-4">
          {members.length > 0 ? (
            members.map((member, index) => (
              <TeamMember key={index} {...member} />
            ))
          ) : (
            <p className="text-center">No team members available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
