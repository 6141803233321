import React from 'react';

const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-secondary footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">Adresse</h5>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>PK6 lot 65 C/5124 quartier Tokplégbé, Calavi Bénin
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>(229) 01 66-20-18-83
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>contact@cyber-afrik.com
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="#">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">Services</h5>
            <a className="btn btn-link" href="#">
              Consultation des Fuites de Données
            </a>
            <a className="btn btn-link" href="#">
              Cartographie de la Surface d'Attaque
            </a>
            <a className="btn btn-link" href="#">
              Visualisation des Risques
            </a>
            <a className="btn btn-link" href="#">
              Détection de Typosquatting
            </a>
           
          </div>
         
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">Newsletter</h5>
            <p>Recevez les dernières nouvelles et mises à jour directement dans votre boîte de réception.</p>
            <div className="position-relative w-100">
              <input
                className="form-control bg-transparent border-secondary w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Votre email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                S'abonné
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
