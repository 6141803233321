import React, { useEffect } from "react";
import PageWrapper from "../components/dashboard/Pagemain";
import 'bootstrap/dist/css/bootstrap.min.css'; // Importer le CSS de Bootstrap import { Button, Dropdown, DropdownButton } from 'react-bootstrap'

const Dashboard = () => {

 
  useEffect(() => {
    // Charger le CSS spécifique au tableau de bord
    const dashboardCss = document.createElement('link');
    dashboardCss.rel = 'stylesheet';
    dashboardCss.href = `${process.env.PUBLIC_URL}/assets/css/dashboard.css`;

    const adminCss = document.createElement('link');
    adminCss.rel = 'stylesheet';
    adminCss.href = `${process.env.PUBLIC_URL}/assets/css/sb-admin-2.css`;

    document.head.appendChild(dashboardCss);
    document.head.appendChild(adminCss);

    // Charger le JS spécifique au tableau de bord
    const dashboardJs = document.createElement('script');
    dashboardJs.src = `${process.env.PUBLIC_URL}/assets/js/dashboard.js`;
    dashboardJs.async = true;

    const dashboardJsmin = document.createElement('script');
    dashboardJsmin.src = `${process.env.PUBLIC_URL}/assets/js/sb-admin-2.min.js`;
    dashboardJsmin.async = true;

    document.body.appendChild(dashboardJs);
    document.body.appendChild(dashboardJsmin);

    // Nettoyage : supprimer les fichiers CSS et JS lorsque le composant est démonté
    return () => {
      document.head.removeChild(dashboardCss);
      document.head.removeChild(adminCss);
      document.body.removeChild(dashboardJs);
      document.body.removeChild(dashboardJsmin);
    };
  }, []);

  return (
   

   
    <div className="dashboard ">
      <PageWrapper />  
 
    </div>
  );
};

export default Dashboard;
