import React from 'react';

const SidebarToggle = ({ toggleSidebar }) => {
  return (
    <button
      id="sidebarToggleTop"
      className="btn btn-link d-md-none rounded-circle mr-3"
      onClick={toggleSidebar}
    >
      <i className="fa fa-bars"></i>
    </button>
  );
};

export default SidebarToggle;
