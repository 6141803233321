import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import iconDataLeak from '../../assets/img/icon/breach.png'; // Assurez-vous que les chemins sont corrects
import iconAttackSurface from '../../assets/img/icon/vulnerability.png'; // Assurez-vous que les chemins sont corrects
import iconRiskVisualization from '../../assets/img/icon/identity.png'; // Assurez-vous que les chemins sont corrects
import iconTyposquatting from '../../assets/img/typo.png'; // Assurez-vous que les chemins sont corrects

const Service = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const services = [
    { icon: iconDataLeak, title: "Consultation des Fuites de Données", description: "Permet de consulter les fuites de données concernant votre entreprise via Have I Been Pwned, LeakCheck, et Naz.api." },
    { icon: iconAttackSurface, title: "Cartographie de la Surface d'Attaque", description: "Cartographie automatique de la surface d'attaque de votre entreprise avec des données publiques comme Shodan et Assetfinder." },
    { icon: iconRiskVisualization, title: "Visualisation des Risques", description: "Visualisation graphique de l’évolution de la surface d’attaque et des risques associés via des graphes et courbes de tendances." },
    { icon: iconTyposquatting, title: "Détection de Typosquatting", description: "Surveillance des domaines usurpant les marques de votre entreprise et envoi de demandes de takedown." }
  ];

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center" data-aos="fade-up" data-aos-delay="100">
          <div className="bg-primary mb-3 mx-auto" style={{ width: "60px", height: "2px" }}></div>
          <h1 className="display-5 mb-5">Nos Services</h1>
        </div>
        <div className="row g-4">
          {services.map((service, index) => (
            <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={`${200 * index}`}>
              <div className="service-item border h-100 p-5">
                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: "64px", height: "64px" }}>
                  <img className="img-fluid" src={service.icon} alt="Icon" />
                </div>
                <h5 className="mb-3">{service.title}</h5>
                <p className="mb-0">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
