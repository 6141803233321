import React from 'react';
import axios from 'axios';
import 'animate.css';

const styles = {
  profileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f9f9f9',
  },
  profileCard: {
    background: 'white',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '100%',
    animationDuration: '2s',
  },
  profilePicture: {
    borderRadius: '50%',
    width: '150px',
    height: '150px',
    objectFit: 'cover',
    marginBottom: '20px',
  }
};

class Profile extends React.Component {
  state = {
    userProfile: {
      name: '',
      email: '',
      profession: '',
      bio: '',
      profilePicture: ''
    }
  };

  componentDidMount() {
    // Remplacez l'URL avec votre propre endpoint API
    axios.get('http://cyber-afrik.tech/api/user-profile')
      .then(response => {
        this.setState({ userProfile: response.data });
      })
      .catch(error => {
        console.error("There was an error fetching the user profile!", error);
      });
  }

  render() {
    const { name, email, profession, bio, profilePicture } = this.state.userProfile;

    return (
      <div style={styles.profileContainer}>
        <div style={{ ...styles.profileCard, ...{ className: 'animate__animated animate__fadeIn' } }}>
          <img src={profilePicture} alt="Profile" style={styles.profilePicture} />
          <h2>{name}</h2>
          <p><strong>Email:</strong> {email}</p>
          <p><strong>Profession:</strong> {profession}</p>
          <p>{bio}</p>
        </div>
      </div>
    );
  }
}

export default Profile;
