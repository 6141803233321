import React from "react";
import Facts from "../components/home/Facts";
import About from "../components/home/About";

import Team from "../components/home/Team";
import Testimonial from "../components/home/Testimonial";


const Home = () => {

  
  return (
    <div>
    
      <Facts />
      <About />
      <Team />
      <Testimonial />

    </div>
  );
};

export default Home;
