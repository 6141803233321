import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'animate.css';
import carousel1 from '../../assets/img/proxy-image.jpeg';
import carousel2 from '../../assets/img/proxy-image1.jpeg';
import carousel3 from '../../assets/img/proxy-image.jpeg';

const Carousel = () => {
  const options = {
    items: 1,
    autoplay: true,
    smartSpeed: 1500,
    loop: true,
    nav: true,
    navText: [
      '<i class="bi bi-chevron-left"></i>',
      '<i class="bi bi-chevron-right"></i>',
    ],
    dots: true,
  };

  const slides = [
    {
      img: carousel1,
      title: "Meilleure Solution de Sécurité et CCTV",
      description: "Nous offrons des solutions de sécurité avancées pour protéger vos locaux contre toute menace potentielle.",
    },
    {
      img: carousel2,
      title: "Solutions de Sécurité Intelligente pour Entreprises",
      description: "Nos solutions de sécurité intelligentes sont conçues pour répondre aux besoins spécifiques de votre entreprise.",
    },
    {
      img: carousel3,
      title: "Solutions Innovantes pour Systèmes de Sécurité",
      description: "Découvrez nos solutions innovantes pour améliorer la sécurité de vos systèmes et infrastructures.",
    },
  ];

  return (
    <div className="container-fluid p-0 pb-5">
      <OwlCarousel className="owl-carousel header-carousel" {...options}>
        {slides.map((slide, index) => (
          <div className="owl-carousel-item position-relative animate__animated animate__fadeInUp" key={index}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={slide.img} alt="" />
            <div className="carousel-inner">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-8 text-center">
                    <h1 className="display-3 text-white animate__animated animate__slideInDown mb-4">{slide.title}</h1>
                    <p className="fs-5 text-white mb-4 pb-2 animate__animated animate__fadeIn">{slide.description}</p>
                    <a href="#quote" className="btn btn-primary rounded-pill py-md-3 px-md-5 me-3 animate__animated animate__slideInLeft">En savoir plus</a>
                    <a href="/contact" className="btn btn-light rounded-pill py-md-3 px-md-5 animate__animated animate__slideInRight">Nous contacter</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Carousel;
