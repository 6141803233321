import React from 'react';

const Spinner = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <dotlottie-player
        src="https://lottie.host/59eed308-f33e-43b4-b421-91e3d48fc7cd/6mx2nKuwiY.lottie"
        background="transparent"
        speed="1"
        style={{ width: 300, height: 300 }}
        loop
        autoplay
      ></dotlottie-player>
    </div>
  );
};

export default Spinner;
