import React from "react";
import iconBreach from '../../assets/img/icon/breach.png'; // Assurez-vous que les chemins sont corrects
import iconAttack from '../../assets/img/icon/vulnerability.png'; // Assurez-vous que les chemins sont corrects
import iconSupport from '../../assets/img/icon/customer-service.png'; // Assurez-vous que les chemins sont corrects

const Facts = () => {
  const facts = [
    { icon: iconBreach, number: "01", title: "Consultation des Fuites de Données", description: "Surveillez et consultez les fuites de données concernant votre entreprise avec Have I Been Pwned, LeakCheck et Naz.api." },
    { icon: iconAttack, number: "02", title: "Cartographie de la Surface d'Attaque", description: "Obtenez une cartographie automatique de la surface d'attaque de votre entreprise avec Shodan, Amass et Assetfinder." },
    { icon: iconSupport, number: "03", title: "Support 24/7", description: "Assistance continue pour garantir la sécurité et la surveillance de votre infrastructure numérique, disponible à toute heure." },
  ];

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-4">
          {facts.map((fact, index) => (
            <div className="col-md-4 col-lg-4 wow fadeIn" data-wow-delay={`${0.1 * (index + 1)}s`} key={index}>
              <div className="h-100 bg-primary p-3 p-xl-4" style={{ minHeight: '50px', maxWidth: '300px', margin: 'auto' }}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="btn-square rounded-circle" style={{ width: "50px", height: "50px", background: "#ffffff" }}>
                    <img className="img-fluid" src={fact.icon} alt="Icon" />
                  </div>
                  <h1 className="display-1 mb-0" style={{ color: "#ffffff" }}></h1>
                </div>
                <h5 className="text-white">{fact.title}</h5>
                <hr className="w-25" />
                <span>{fact.description}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Facts;
