import React, { useState } from 'react';
import Sidebar from './SuperadminSidebar';
import ContentWrapper from './swhitch/ContentWrapper';
import ScrollToTop from './ScrollToTop';
import LogoutModal from './LogoutModal';
import PageHeading from './PageHeading';
const PageWrapper = () => {
  const [activeContent, setActiveContent] = useState('default');

  return (
    <div id="page-top">
      <div id="wrapper">
        <Sidebar setActiveContent={setActiveContent} /> {/* Passer setActiveContent comme prop */}
        <ContentWrapper activeContent={activeContent} />
      </div>
      <ScrollToTop />
      <LogoutModal />
    </div>
  );
};

export default PageWrapper;
