import React from 'react';

const Copyright = () => {
  return (
    <div className="container-fluid py-4" style={{ background: '#000000' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy; Cswt, All Right Reserved.@2024
          </div>
          <div className="col-md-6 text-center text-md-end">
            Designed By <a href="https://portfolio.cyber-afrik.com/Accueil">CyberAfrik</a>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
