import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import QuestionImg from '../../assets/img/questions.jpg'; // Assurez-vous que les chemins sont corrects

const Quote = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0" id="quote">
      <div className="container questions px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 ps-lg-0" style={{ minHeight: '200px' }} data-aos="fade-up">
            <div className="position-relative h-100">
              <img
                className="img-fluid w-75 h-75"
                src={QuestionImg}
                style={{ objectFit: 'cover', position: 'relative', zIndex: '0.2', top: '100px', left: "60px", }} // Ajustez la valeur de 'top' selon vos besoins
                alt="Questions"
              />
            </div>
          </div>
          <div className="col-lg-6 questions-text py-5" data-aos="fade-left" data-aos-delay="500">
            <div className="p-lg-5 pe-lg-0">
              <div className="bg-primary mb-3" style={{ width: '60px', height: '2px' }}></div>
              <h1 className="display-5 mb-5">Avez-vous des questions ?</h1>
              <p className="mb-4 pb-2">
                Nous sommes là pour vous aider. Si vous avez des questions concernant nos services, n'hésitez pas à nous contacter en utilisant le formulaire ci-dessous. Nous serons ravis de vous fournir les informations dont vous avez besoin.
              </p>
              <form>
                <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <input type="text" className="form-control border-0" placeholder="Votre Nom" style={{ height: '55px' }} />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input type="text" className="form-control border-0" placeholder="Votre Prénom" style={{ height: '55px' }} />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input type="email" className="form-control border-0" placeholder="Votre Email" style={{ height: '55px' }} />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input type="text" className="form-control border-0" placeholder="Votre Téléphone" style={{ height: '55px' }} />
                  </div>
                  <div className="col-12">
                    <textarea className="form-control border-0" placeholder="Votre Question"></textarea>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3" type="submit">
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
