import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SocialButtonWrapper = styled.div`
  transition: all 0.4s;
  position: fixed;
  right: 25px;
  bottom: 25px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  background-color: #2186EC;
  color: #fff;
  font-size: 24px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  cursor: pointer;
  z-index: 1000;
  animation: ${rotate} 2s infinite linear;
  &:hover {
    animation: none;
  }

  &.social-button-open {
    bottom: 85px;
    background-color: #d81558;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.28), 0 4px 15px 0 rgba(0, 0, 0, 0.25);
  }
`;

const SocialPoint = styled.a`
  transition: all 0.4s;
  opacity: 0;
  width: 0px;
  height: 0px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 24px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  cursor: pointer;
  visibility: hidden;
  position: absolute;
  right: 50px;
  bottom: 50px;

  &.social-point-open {
    opacity: 1;
    width: 50px;
    height: 50px;
    visibility: visible;
  }

  &:nth-child(2) {
    transition-delay: 0.3s;
  }

  &:nth-child(3) {
    transition-delay: 0.4s;
  }

  &:nth-child(4) {
    transition-delay: 0.5s;
  }

  &:nth-child(5) {
    transition-delay: 0.6s;
  }

  &:nth-child(6) {
    transition-delay: 0.7s;
  }

  &.social-point-open:nth-child(2) {
    right: 5px;
    bottom: 80px;
  }

  &.social-point-open:nth-child(3) {
    right: 65px;
    bottom: 65px;
  }

  &.social-point-open:nth-child(4) {
    right: 80px;
    bottom: 5px;
  }

  &.social-point-open:nth-child(5) {
    right: 65px;
    bottom: -65px;
  }

  &.social-point-open:nth-child(6) {
    right: 5px;
    bottom: -80px;
  }

  &.social-point-open:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.28), 0 4px 15px 0 rgba(0, 0, 0, 0.25);
  }
`;

const SocialButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SocialButtonWrapper className={isOpen ? 'social-button-open' : ''} onClick={toggleMenu}>
      <i className={`fas ${isOpen ? 'fa-times' : 'fa-share-alt'}`} id="social-icon"></i>
      <SocialPoint
        className={`social-point ${isOpen ? 'social-point-open' : ''}`}
        href="mailto:contact@cyberafrik.com?subject=Contact%20CyberAfrik"
        title="Email"
      >
        <i className="fas fa-envelope"></i>
      </SocialPoint>
      <SocialPoint
        className={`social-point ${isOpen ? 'social-point-open' : ''}`}
        href="tel:+1234567890"
        title="Téléphone"
      >
        <i className="fas fa-phone-alt"></i>
      </SocialPoint>
      <SocialPoint
        className={`social-point ${isOpen ? 'social-point-open' : ''}`}
        href="https://wa.me/1234567890"
        title="WhatsApp"
      >
        <i className="fab fa-whatsapp"></i>
      </SocialPoint>
      <SocialPoint
        className={`social-point ${isOpen ? 'social-point-open' : ''}`}
        href="https://www.linkedin.com/company/cyberafrik-sarl/"
        target="_blank"
        rel="nofollow"
        title="LinkedIn"
      >
        <i className="fab fa-linkedin"></i>
      </SocialPoint>
      <SocialPoint
        className={`social-point ${isOpen ? 'social-point-open' : ''}`}
        href="https://www.instagram.com/cyberafrik_sarl/"
        target="_blank"
        rel="nofollow"
        title="Instagram"
      >
        <i className="fab fa-instagram"></i>
      </SocialPoint>
    </SocialButtonWrapper>
  );
};

export default SocialButton;
