import React from 'react';
import Button from '../../buttons/Button';

const CompanieList = ({ users, deleteUser }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Liste des Compagnies </h2>
          <table className="table table-striped table-responsive-md">
            <thead>
              <tr>
                <th>Nom </th>
                <th>Détails 1</th>
                <th>Détails 2</th>
                <th>Détails 3</th>
                <th>Détails 2</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompanieList;
