import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import aboutImage from '../../assets/img/icon/image.png';
import icon1 from '../../assets/img/icon/icon-1.png';
import icon5 from '../../assets/img/icon/icon-5.png';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container about px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
            <div className="position-relative h-100" data-aos="fade-up">
              <img 
                className="position-absolute img-fluid w-75 h-75" 
                src={aboutImage} 
                style={{ objectFit: 'cover', position: 'relative', zIndex: '0.2', top: '100px', left: "60px", }}  
                alt="" 
              />
            </div>
          </div>
          <div className="col-lg-6 about-text py-5" data-aos="fade-left" data-aos-delay="500">
            <div className="p-lg-5 pe-lg-0">
              <div className="bg-primary mb-3" style={{ width: "60px", height: "2px" }}></div>
              <h1 className="display-5 mb-4">À propos de nous</h1>
              <p className="mb-4 pb-2">
                Chez CyberAfrik SECURITY WEB TOOLS (CSWT), notre mission est de fournir aux entreprises les outils nécessaires pour protéger proactivement leur cybersécurité. Nous comprenons les défis uniques auxquels sont confrontées les entreprises modernes en matière de sécurité numérique, et nous nous engageons à offrir des solutions innovantes et efficaces pour surmonter ces défis.
              </p>
              <p className="mb-4 pb-2">
                Nous aspirons à créer un environnement numérique sûr pour toutes les entreprises, en leur fournissant les moyens de surveiller et de gérer efficacement leur sécurité en ligne. En combinant des technologies de pointe avec une expertise approfondie en cybersécurité, nous aidons nos clients à protéger leurs actifs les plus précieux contre les menaces potentielles.
              </p>
              <div className="row g-4 mb-4 pb-3">
                <div className="col-sm-6" data-aos="fade-up" data-aos-delay="100">
                  <div className="d-flex align-items-center">
                    <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                      <img className="img-fluid" src={icon1} alt="Icon" />
                    </div>
                    <div className="ms-4">
                      <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                      <p className="fw-medium text-primary mb-0">Clients Satisfaits</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6" data-aos="fade-up" data-aos-delay="300">
                  <div className="d-flex align-items-center">
                    <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                      <img className="img-fluid" src={icon5} alt="Icon" />
                    </div>
                    <div className="ms-4">
                      <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                      <p className="fw-medium text-primary mb-0">Projets Réalisés</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
