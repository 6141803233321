import React from "react";
import SidebarToggle from "./SidebarToggle";
import TopbarSearch from "./TopbarSearch";
import SearchDropdown from "./SearchDropdown";
import AlertsDropdown from "./AlertsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import TopbarDivider from "./TopbarDivider";
import UserDropdown from "./UserDropdown";

const Topbar = ({ toggleSidebar, setActiveContent }) => {
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      {/* Sidebar Toggle (Topbar) */}
      <SidebarToggle toggleSidebar={toggleSidebar} />

      {/* Topbar Search */}
      <TopbarSearch />

      {/* Topbar Navbar */}
      <ul className="navbar-nav ml-auto">
        {/* Nav Item - Search Dropdown (Visible Only XS) */}
        <SearchDropdown />

        {/* Nav Item - Alerts */}
        <AlertsDropdown />

        {/* Nav Item - Messages */}
        <MessagesDropdown />

        <TopbarDivider />
      
        {/* Nav Item - User Information */}
        <UserDropdown setActiveContent={setActiveContent} />
      </ul>
    </nav>
  );
};

export default Topbar;
