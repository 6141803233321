import React from 'react';
import Button from '../../buttons/Button';

const AdminList = ({ users, deleteUser }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Liste des Admins</h2>
          <table className="table table-striped table-responsive-md">
            <thead>
              <tr>
                <th>Nom de l'admin</th>
                <th>Email</th>
                <th>autres infos </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <th></th>
              <th></th>
              <th></th>
              <th><Button /> </th>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminList;
