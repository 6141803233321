import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OwlCarousel from 'react-owl-carousel';

const Testimonial = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const options = {
    items: 1,
    autoplay: true,
    smartSpeed: 1000,
    loop: true,
    nav: false,
    dots: true,
    dotsData: true,
  };

  return (
    <div className="container-xxl py-5" data-aos="fade-up" data-aos-delay="100">
      <div className="container">
        <div className="text-center" data-aos="fade-up" data-aos-delay="100">
          <div className="bg-primary mb-3 mx-auto" style={{ width: '60px', height: '2px' }}></div>
          <h1 className="display-5 mb-5">Témoignages</h1>
        </div>
        <OwlCarousel className="owl-carousel testimonial-carousel" {...options}>
          <div className="testimonial-item text-center" data-aos="fade-up" data-aos-delay="200">
            <p className="fs-5">
              "Grâce à CSWT, nous avons pu identifier et résoudre des failles de sécurité critiques avant qu'elles ne deviennent problématiques. Leur service de surveillance des fuites de données est exceptionnel."
            </p>
            <h4>Jean Dupont</h4>
            <span className="text-primary">Directeur de la Sécurité, Entreprise XYZ</span>
          </div>
          <div className="testimonial-item text-center" data-aos="fade-up" data-aos-delay="300">
            <p className="fs-5">
              "La cartographie de la surface d'attaque fournie par CSWT nous a permis de visualiser et de gérer efficacement notre exposition aux risques. Un outil indispensable pour toute entreprise soucieuse de sa cybersécurité."
            </p>
            <h4>Marie Curie</h4>
            <span className="text-primary">Responsable IT, Société ABC</span>
          </div>
          <div className="testimonial-item text-center" data-aos="fade-up" data-aos-delay="400">
            <p className="fs-5">
              "Leur service de détection de typosquatting nous a aidés à protéger notre marque contre les usurpations de domaine. CSWT est un partenaire de confiance pour notre sécurité en ligne."
            </p>
            <h4>Paul Martin</h4>
            <span className="text-primary">CEO, Startup DEF</span>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Testimonial;
