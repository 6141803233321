import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../components/Navbar';  
import Footer from '../components/Footer';  

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ajoutez ici la logique pour envoyer les données du formulaire à votre backend
    console.log(formData);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid bg-light overflow-hidden px-lg-0">
        <div className="container contact px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 contact-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 ps-lg-0">
                <div className="section-title text-start">
                  <h1 className="display-5 mb-4">Contactez-nous</h1>
                </div>
                <p className="mb-4">Nous sommes là pour répondre à toutes vos questions. Veuillez remplir le formulaire ci-dessous, et nous vous répondrons dans les plus brefs délais.</p>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Votre Nom"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        <label htmlFor="name">Votre Nom</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Votre Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <label htmlFor="email">Votre Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Sujet"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                        <label htmlFor="subject">Sujet</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Laissez un message ici"
                          id="message"
                          style={{ height: '100px' }}
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">
                        Envoyer le message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 pe-lg-0 mt-5" style={{ minHeight: '400px' }}>
              <div className="position-relative mt-5 h-100">
                <iframe                                                             
                  title="Location"
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: 'cover' }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.248700221575!2d2.4926304999999997!3d6.3618498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103cabf541a6ab63%3A0x741c55a72c7d7287!2sCyberAfrik!5e0!3m2!1sfr!2sbj!4v1732205362607!5m2!1sfr!2sbj"
                  frameBorder="0"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
